.header-container {
  height: 50px;
  display: flex;
  flex-direction: "row";
  align-items: center;
  font-family: "Montserrat-Medium";
  padding: 0 5px 0 20px;
  margin-bottom: 5px;
}
.header-cross-container {
  position: absolute;
  right: 15px;
}

.error-container {
  background-color: #db4437;
}

.warning-container {
  background-color: #f4b024;
}

.bannerText {
  font-size: 12px;
  margin: 0 15px 0 15px;
  max-width: 90%;
}

.text-error {
  color: #ffffff;
}

.text-warning {
  color: #231f20;
}
