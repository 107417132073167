.rs-picker-menu {
  left: 60% !important;
}

.sticky {
  position: sticky !important;
  /* height: 41px; */
  /* left: 0; */
  /* top: 0; */
  /* z-index: 1; */
  /* background-color: white; */
}
.table-header {
  height: 41px;
}

.table-body {
  display: flex;
  flex-direction: column;
}

.tableHeaderStyle {
  /* flex: 1; */
  flex-grow: 100;
  flex-shrink: 0;
  flex-basis: auto;
}

.updatedTableDataCol {
  /* flex: 1; */
  flex-grow: 100;
  flex-shrink: 0;
  flex-basis: auto;
}

.table-row {
  width: auto;
}
